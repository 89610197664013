@font-face {
    font-family: 'Jura';
    src: url('../fonts/Jura-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Jura';
    src: url('../fonts/Jura-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Jura';
    src: url('../fonts/Jura-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Jura';
    src: url('../fonts/Jura-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Jura';
    src: url('../fonts/Jura-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #222222;
}

::-webkit-scrollbar-thumb {
    background: #33E4F1;
    border-radius: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;